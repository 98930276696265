import axios from "axios";

window.Axios = axios;
window.Axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Adds a loading animation inside an element
 * @param {string|HTMLElement} parentElement
 * @param {boolean} backdrop
 */
window.addLoader = (
  parentElement,
  { backdrop = false, color = "#fff", align = "left" } = {}
) => {
  if (!(parentElement instanceof HTMLElement)) {
    parentElement = document.querySelector(parentElement);
  }

  const loader = document.createElement("div");
  loader.classList.add("ripple");
  const inner1 = document.createElement("div");
  const inner2 = document.createElement("div");
  inner1.style.backgroundColor = color;
  inner2.style.backgroundColor = color;
  if (align == "center") {
    loader.style.display = "block";
    loader.style.margin = "auto";
  }
  loader.append(inner1, inner2);

  if (backdrop) {
    const backdrop = document.createElement("div");
    backdrop.className = "loader backdrop";
    backdrop.style.zIndex = 1000001;

    backdrop.append(loader);
    parentElement.append(backdrop);
    return;
  }

  loader.classList.add("loader");
  parentElement.append(loader);
};

/**
 * Removes the animation inside an element
 * @param {string|HTMLElement} parentElement
 */
window.removeLoader = (parentElement) => {
  if (!(parentElement instanceof HTMLElement)) {
    parentElement = document.querySelector(parentElement);
  }

  parentElement.querySelector(":scope > .loader").remove();
};

/**
 * Shows server side error messages on forms
 * @param {HTMLFormElement} form - The form object
 * @param {JSON} errors - JSON Response in Laravel's error response format
 */
window.handleSubmitError = (form, response) => {
  // Remove previous error messages and classes
  form.querySelectorAll(".alert-danger").forEach((el) => el.remove());
  form.querySelectorAll(".text-danger").forEach((el) => el.remove());
  form
    .querySelectorAll(".is-invalid")
    .forEach((el) => el.classList.remove("is-invalid"));

  if (response && response.errors) {
    for (const key in response.errors) {
      const input = form.querySelector(`[name="${key}"]`);
      input.classList.add("is-invalid");
      input.insertAdjacentHTML(
        "afterend",
        `<div class="text-danger">${response.errors[key]}</div>`
      );
    }
  } else if (response.message) {
    const alert = document.createElement("div");
    alert.className = "alert alert-danger";
    alert.innerText = response.message;
    form.prepend(alert);
  }
};

/**
 *
 * @param {Event} e
 * @param {HTMLFormElement} form
 */
window.handleFormSubmit = async (e, form) => {
  e.preventDefault();

  addLoader(document.body, { backdrop: true, align: "center" });

  await axios({
    method: form.method,
    url: form.action,
    data: new FormData(form),
    headers: { Accept: "application/json" },
  })
    .then((response) => {
      localStorage.setItem("session_success", response.data.message);
      location.reload();
    })
    .catch((error) => {
      console.error(error);
      removeLoader(document.body);
      handleSubmitError(form, error.response.data);
    });
};

/**
 *
 * @param {string} content - HTML as string
 * @param {string} type - Bootstrap theme color type
 * @param {number} duration
 */
window.showToast = (content, type = "success", duration = 7000) => {
  let container = document.querySelector(".floating-message-container");
  if (!container) {
    container = document.createElement("div");
    container.className = "floating-message-container";
    document.body.appendChild(container);
  }

  const alert = document.createElement("div");
  alert.className = `floating-message floating-message-${type}`;

  let icon = "";
  switch (type) {
    case "success":
      icon = '<i class="fa-solid fa-check-circle"></i>';
      break;
    case "danger":
      icon = '<i class="fa-solid fa-exclamation-circle"></i>';
      break;
    case "warning":
      icon = '<i class="fa-solid fa-exclamation-circle"></i>';
      break;
    default:
      icon = '<i class="fa-solid fa-circle-info"></i>';
  }

  content = `<div>${content}</div>`;

  const closeButtonHTML = `
    <button href="button" aria-label="Close" class="close-btn"><i class="fa-solid fa-xmark"></i></button>
  `;

  alert.innerHTML = `${icon} ${content} ${closeButtonHTML}`;
  container.append(alert);

  setTimeout(() => {
    removeToast(alert, container);
  }, duration);

  alert.querySelector(".close-btn").addEventListener("click", () => {
    removeToast(alert, container);
  });
};

/**
 *
 * @param {HTMLElement} alert
 * @param {HTMLElement} container
 */
function removeToast(alert, container) {
  alert.classList.add("remove");
  setTimeout(() => {
    alert.remove();

    if (!document.querySelector(".floating-message")) {
      container.remove();
    }
  }, 300);
}
